@import 'common-flagship_tasting_room';

//stylelint-disable
.pswp__img {
	background: $color-product_image-background;
}

.b-dialog-btn.m-alt {
	border: none;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			background: var(--button_alt-background_color, $color-button_alt-background);
			color: var(--button_alt-text_color, $color-button_alt-text);
		}
	}
}

.b-dialog-btn.m-alt .b-dialog-btn_text {
	@include t-link_underlined($state: default, $underline-offset: 5px, $font-weight: inherit);
	@include t-link_underlined($state: hover);

	letter-spacing: inherit;
}

.b-header.m-transparent:not(.m-inverted):not(.m-active):not(.m-stuck):not(.m-hamburger_opened) {
	color: inherit;
}

.b-header-background {
	border-bottom: 1px solid $color-tertiary;
}
.b-categories_navigation { 
	 &-item_1:after {
		border-bottom: 3px solid $color-secondary;
 	}
	&-group_1 {
		border-top: 1px solid $color-tertiary;
		.t-link_1 , .t-heading_6 {
			color: $color-tertiary;
		}
	}
	&-link_1 {
		font-size: 16px;
	}
}

.b-categories_navigation-list_1 {
	color: $color-secondary !important;
	font-family: $font-alt;
	font-size: 16px !important;
	letter-spacing: 0.5px;
	line-height: 24px;
	font-weight: 500;
}

.b-categories_navigation-link_2 , .b-categories_navigation-link_3.m-view_all {
	color: $color-primary;
}

.b-product {
	&_tile-name {
		color: $color-secondary;
		line-height: 24px;
	}
	&_tile-image {
		background: $color-primary-background;
	}
	&_price-value {
		font-weight: 300;
	}
}

svg {
	stroke: $color-secondary;
}

.b-product_gallery-thumb.m-current:after{
    border-color: $color-tertiary !important;
}
.b-product_tile-cta {
	display: none;
}

.b-breadcrumbs {
	&-link {
		color: $color-tertiary !important;
	}
    &-item {
		font-size: 16px;
        align-items: baseline;
        &:not(.m-current) {
            @include media(md-up) {
                @include icon('arrow-right', 8px, 8px, after) {
                    background: $color-secondary;
                    margin: 2px 15px 0;
                }
            }
        }
    }
}

.b-footer-contacts .content-asset {
	@include media(sm) {
		padding-bottom: 0;
	}
}
.b-footer {
	&-customer_service , &-need_help{
		visibility: hidden;
	}
	&-bottom_info, &_legal-links {
		@include media(sm) {
			align-items: center;
		}
	}
	&-copyright {
		color: $color-primary !important;
		opacity: initial;
		font-size: 12px;
		line-height: 18px;
	}
	&-social {
		@include media(md) {
			grid-area: auto !important;
		}
	}
	&_social {
		@include media(md-up) {
			padding-top: 64px;
		}
		text-align: left;
		&-title {
			text-transform: capitalize;
			letter-spacing: 0.3px;
			color: $color-primary !important;
			font-family: $font-main;
			font-weight: 400;
			@include media(sm) {
				text-align: center;
			}
		}
		&-links {
			justify-content: flex-start;
		   & a:not(:last-child) {
				padding-right: 16px;
			}
			@include media(sm) {
				justify-content: center;
			}
		}
	}
	@include media(md-down) {
		background: transparent;
	}
	background: transparent;
	border-top: 1px solid $color-shade_5;
	&_navigation {
		&-content {
			@include media(md-down) {
				align-items: center;
				letter-spacing: 0.4px;
			}
		}
		&-link {
			letter-spacing: 0.5px !important;
			color: $color-tertiary;
		}
		&-title {
			text-transform: capitalize;
			letter-spacing: 0.5px;
			color: $color-primary!important;
			font-family: $font-main;
			font-weight: 200;
			@include media(md-down) {
				text-align: center;
			}
		}
	}
	
}

.logo {
	&-desktop {
		margin-top: 5%;
		@include media(sm){
			display: none;
	
		}
		img {
			width: 100%;
		}
	}
	&-mobile {
		display: none;
		margin-left: -5%;
		top:-3%;
		@include media(sm){
			display: flex;
		}
		img {
			width: 100%;
			object-position: left;
    		object-fit: cover;
			margin-top: 12px;
			margin-bottom: 12px;
			@include media(sm){
				margin: 0;
			}
		}
	}
}

.b-header.m-stuck .logo-desktop {
	display: none;
}
.b-header.m-stuck .logo-mobile {
	display: flex;
}

.f-input_checkbox input , .f-input_radio-field , .f-input_radio-field.m-checked, .f-input_select:before {
	background: $color-secondary;
}
.b-refinements_accordion-button:after {
    background: var(--icon-color,$color-secondary) !important;
}
.b-refinement_list-more {
	color: $color-secondary;
}
.b-refinements_accordion-button {
	font-size: 16px !important;
}

.l-plp-title {
	font: $configuration-t_heading_2-font !important;
	color: $color-tertiary;
	font-weight: 500 !important;
}
.l-products_results-products_number {
	font-size: 400 16px/24px !important;
	text-transform: capitalize !important;
	letter-spacing: 0.4px;
	font-weight: 500;
}

.l-products_results {
	&-sorting {
		@include media(md-down){
			grid-template-columns: 1fr 1fr;
		}
	}
	
}

.l-products_results-refinement_button {
	background: $color-secondary !important;
}

.b-product_badge {

	&.m-promo {
		background: $color-secondary;
	}
	color: $color-primary-background !important;
	&-image {
		background: none !important;
		border: none;
	}
	&.m-rating_points {
		background-image: url('./svg-icons/badge-shape.png');
		border-radius: 50%;
		background-size: contain;
	}
	&-abbr, &-text {
		font-size: 16px;
		letter-spacing: 0.15px;
		line-height: 24px;

		@include media(md-down) {
			font-size: 12px;
			letter-spacing: 0.12px;
			line-height: 14px;
		}
	}
	&-points {
		color: $color-secondary;
        font: 500 16px/24px $font-main !important;
        letter-spacing: 0.5px;
	}
	&-text {
        font: 500 12px/18px $font-main !important;
        letter-spacing: 0.3px;
        color:$color-tertiary;
    }
}

.b-carousel-control {
    border: 0;
}

.b-variations_item-label {
    color: $color-shade_3 !important;
}

// subheading in homepage
.subhead_ {
	font-family: $font-main;
	font-size: 28px;
	letter-spacing: 0.5px;
	line-height: 40px;
	color: $color-shade_7;
	font-weight: 200;
}

.b-carousel-title {
  font-family: $font-alt;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 46px;
  margin-left:25px;
  color: $color-tertiary;
}

.mainhead {
	font-family: $font-alt;
	font-size: 48px;
	letter-spacing: 0.5px;
	line-height: 56px;
	text-align: center;
	font-weight: 100;
    margin-bottom: 56px;
}

.b-header-links_item:before, .b-header-utility_item:before {
	background: $color-secondary !important;
}

.b-store_locator-title {
	font-family: $font-alt;
	font-size: 36px;
	letter-spacing: 0.3px;
	line-height: 48px;
}

.b-header_promo {
	background: $color-shade_8;
	&-content {
		color: $color-primary;
		font-family: $font-main;
		font-size: 16px;
		letter-spacing: 0.5px;
		line-height: 24px;
		& a {
			color: $color-tertiary;
		}
	}
}

.b-carousel {
    &-control{
        border: 0;
        --icon-color: var(--carousel-control-color, #{$color-carousel_control});
        &[disabled] {
            --icon-color: var(--carousel-control-color, #{$color-carousel_control__disabled});
            opacity: initial;
            }
    }
    &-pagination_control{
        border: 1px solid $color-tertiary;
		&.m-current {
			color: $color-carousel_pagination_control__active;
		}
    }
}

.f-input_text-label span{
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 400px;
	@include media(sm){
		max-width: 290px;
	}
}

.b-header-links_item.m-stores , .b-header-utility_item.m-search { 
	@include media(md-up){
		font-size: 16px;
	}
}
.g-button_main { 
	font-family: $font-alt !important;
}
.b-footer_legal-link {
	font-size: 12px;
	line-height: 18px;
	color: $color-tertiary;
}

// Hover line alignment in nav
.b-header {
	&.m-stuck {
		.b-categories_navigation-link_1 {
			padding: 16px 0 25px !important;
		}
	}
}

.t-paragraph_1 {
	font-size: 16px !important;
	color: $color-primary;
	letter-spacing: 0.5px;
	line-height: 24px;
}

// back to top icon
.b-back_to_top {
		&-icon {
			margin-bottom: 0 !important;
			&_circle {
				color: $color-secondary !important;
			}
		}
		&-copy {
			font-size: 16px;
			text-transform: capitalize;
			font-weight: 300;
			color: $color-shade_9;
			margin-top: 10px;
		}
	
}

//AGE GATE
.b-age_gate{
	&-inner {
		color: $color-primary;
	}
	&-button {
		color: $color-primary-background;

		&:hover {
			background-color: $color-primary;
			color: $color-secondary;
		}
	}
}

.f-input_text-label span{
    overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 400px;
	@include media(sm){
		max-width: 320px;
	}
}

.b-footer_social-link.m-instagram:before,
.b-footer_social-link.m-facebook:before, 
.b-footer_social-link.m-pinterest:before,
.b-footer_social-link.m-twitter:before,
.b-footer_social-link.m-youtube:before {
	background: $color-secondary;
}

.f-input_checkbox-field { 
    margin: 10px 10px 0 0;
	background: $color-secondary;
}

.f-input_select-field { 
	padding: 12px 25px 8px 15px;
	@include media(sm){
		padding-bottom: 15px;
    	padding-top: 15px;
	}
}

.b-customer_service_info {
	&-icon {
		color:$color-secondary !important;
		stroke: none;
	}
	&-link { 
		color:$color-shade_1 !important;
	}
}

.b-text_box p { 
	font-size: 16px !important;
	color: $color-primary;
}

.experience-component {
	&.experience-commerce_assets-textBox {
		background-color: $color-shade_8;
	}
}

.b-search_input-clear {
	color: $color-secondary;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	letter-spacing: 0.5px;
	text-decoration: underline;
}

.b-contact_us-form_link{
	color: $color-secondary;
}

.l-page #wtb-overlay #closeBtn:after{
	color: $color-secondary !important;
}

.b-header-hamburger_cta{
	color: $color-secondary;
}

.b-search_input-input {
	font-weight: 500 !important;
}

.b-header-hamburger_cta:before {
	color: $color-secondary-text;
}

.b-button_close:before{
	color: $color-secondary;
}

.b-header-close_hamburger:before { 
	color: $color-secondary;
}

.b-store_locator-link:after{ 
	color: $color-secondary;
}

.b-search_input-submit:before{
	color: $color-secondary;
}

.b-header-navigation_link_inner:before{
	color: $color-secondary;
}

.b-store_locator-link { 
	color: $color-primary !important;
}

.b-form-btn_alt {
	background-color: $color-secondary;
	color: $color-primary-background;
	&:hover {
		color: $color-secondary !important;
		background-color: $color-primary-background !important;
	}
} 
.b-header-navigation_link.m-stores{
	color: $color-secondary;
	text-transform:uppercase;
}