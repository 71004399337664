// stylelint-disable

// # Source Sans Pro

// ## Regular

// ### latin-ext
@import url("https://use.typekit.net/aki7ecj.css");

//New Fonts
$font-gibson:'canada-type-gibson', sans-serif;


$font-main: $font-gibson;
$font-alt: $font-gibson;



